import moment from 'moment'
import { is, record } from '@/utils/helpers'

type Options = {
  startTime: string
  endTime: string
  renderAreas: AnyObj[]
  currentAreaId: string
}
type PartialOptions = { [K in keyof Options]?: Options[K] }

const number = {
  legalize: (a: unknown) => {
    if (is.num(a) && !isNaN(a)) return a
    if (is.str(a) && !isNaN(parseFloat(a))) return a

    return 0
  }
}

const reasons = [
  { props: ['quality'], name: '质量合格' },
  { props: ['qualityPitch'], name: '俯仰角度过大' },
  { props: ['qualityYaw'], name: '水平角度过大' },
  { props: ['qualityBlur'], name: '过于模糊' },
  { props: ['qualityMouthOcclusion', 'qualityEyeOcclusion'], name: '遮挡' },
  { props: ['attributeMaskFilter'], name: '佩戴口罩' },
  { props: ['attributeSunglassesFilter'], name: '佩戴墨镜' },
  { props: ['qualityNewPersonLowQuality'], name: '新面孔校验' },
  {
    props: [
      'other',
      'qualityNoObject',
      'attributeTemperatureFilter',
      'attributeAgeFilter',
      'errorNoFeature',
      'errorNotFound'
    ],
    name: '其它原因'
  }
]

const dateRange = (startTime: string, endTime: string) => {
  const range = []
  const start = moment(startTime)
  const end = moment(endTime)

  while (start.isSameOrBefore(end)) {
    range.push(moment(start))
    start.add(1, 'day')
  }

  return range
}

export class Store<T extends AnyObj = AnyObj> {
  private data: T[] = []
  private options: Options = {
    startTime: moment().startOf('day').toISOString(),
    endTime: moment().endOf('day').toISOString(),
    renderAreas: [],
    currentAreaId: ''
  }

  get dateRange() {
    const { startTime, endTime } = this.options

    return dateRange(startTime, endTime)
  }

  get currentLocationData() {
    const { currentAreaId } = this.options
    return this.data.filter(({ areaId }) => areaId === currentAreaId)
  }

  get othersLocationData() {
    const { currentAreaId } = this.options
    return this.data.filter(({ areaId }) => areaId !== currentAreaId)
  }

  getData() {
    return this.data
  }

  setData(data: T[]) {
    this.data = data
  }

  setOptions(options: PartialOptions) {
    this.options = { ...this.options, ...options }
  }

  restore() {
    this.data = []
    this.options = {
      startTime: moment().startOf('day').toISOString(),
      endTime: moment().endOf('day').toISOString(),
      renderAreas: [],
      currentAreaId: ''
    }
  }

  getSnapshotsData() {
    const { renderAreas } = this.options
    const statistic: AnyObj = {}
    this.data.forEach(({ areaId, date, total, quality }) => {
      if (!statistic[areaId]) {
        statistic[areaId] = this.dateRange.reduce((prev, curr) => {
          prev[curr.toISOString()] = { total: 0, qualified: 0, unqualified: 0, unqualifiedRate: 0 }
          return prev
        }, {} as AnyObj)
      }

      if (statistic[areaId][moment(date).toISOString()]) {
        statistic[areaId][moment(date).toISOString()] = {
          total,
          qualified: quality,
          unqualified: total - quality,
          unqualifiedRate: total ? Math.round(((total - quality) / total) * 10000) / 100 : 0
        }
      }
    })

    return record(statistic).mapArray((data, id) => {
      const { areaName } = renderAreas.find(area => area.areaId === id) ?? { areaName: '' }

      return {
        id,
        name: areaName,
        data: record(data).mapArray(n => n)
      }
    })
  }

  getTotals() {
    const dateArray: string[] = []
    const accumulated = this.currentLocationData.reduce((prev, curr) => {
      for (const key in curr) {
        if (is.num(curr[key])) {
          prev[key] = (prev[key] ? prev[key] : 0) + curr[key]
        }
      }

      if (!dateArray.includes(curr.date)) {
        dateArray.push(curr.date)
      }

      return prev
    }, {} as AnyObj)

    const dateCount = dateArray.length
    const dateRangeCount = this.dateRange.length
    const { total = 0, quality = 0 } = accumulated

    return record({
      total,
      quality,
      unquality: total - quality,
      average: Math.round(total / dateRangeCount),
      unqualityAverage: Math.round((total - quality) / dateRangeCount),
      unqualityRate: Math.round(((total - quality) / total) * 10000) / 100,
      dateCount,
      dateRangeCount
    }).map(number.legalize)
  }

  getReasons() {
    return reasons.map(({ name }) => name)
  }

  getDataWithReasons() {
    return reasons.map(({ props, name }) => {
      const data = this.dateRange.map(date => {
        let value = 0

        this.currentLocationData
          .filter(item => date.isSame(item.date))
          .forEach(item => {
            for (const prop of props) {
              value += item[prop] ?? 0
            }
          })

        return value
      })

      return { name, data }
    })
  }

  getTotalsWithReasons() {
    return reasons.map(({ props, name }) => {
      let value = 0

      this.currentLocationData.forEach(item => {
        for (const prop of props) {
          value += item[prop] ?? 0
        }
      })

      return { name, value }
    })
  }

  getComparisonData() {
    const reduce = (data: T[], count = 1) => {
      const { dateCount, total, qualified } = data.reduce(
        (prev, curr) => {
          prev.dateCount += 1
          prev.total += curr.total
          prev.qualified += curr.quality

          return prev
        },
        {
          dateCount: 0,
          total: 0,
          qualified: 0
        }
      )

      return {
        dateCount: count ? Math.round(dateCount / count) : 0,
        total: count ? Math.round(total / count) : 0,
        qualifiedRate: total ? Math.round((qualified / total) * 100) / 100 : 0
      }
    }
    const { renderAreas } = this.options
    const othersCount = renderAreas.length - 1

    const current = reduce(this.currentLocationData, 1)
    const others = reduce(this.othersLocationData, othersCount)

    return [
      { name: '抓拍数', current: current.total, others: others.total },
      { name: '上图天数', current: current.dateCount, others: others.dateCount },
      { name: '质量合格率', current: current.qualifiedRate, others: others.qualifiedRate }
    ]
  }
}

export default new Store()
