





































































































































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import PageTitle from '@/components/common/pageTitle.vue'
import store from './store'
import charts from './charts'
import { DatePickerOptions } from 'element-ui/types/date-picker'
import stores, { actionTypes } from '@/store'
import { blobToJson } from '@/utils/helpers'
import _ from 'lodash'

type SnapshotsTabValue = 'total' | 'qualified' | 'unqualified'
type DiscardTabValue = 'unqualified' | 'unqualifiedRate'

@Component({ components: { PageTitle } })
export default class SnapshotsPage extends Vue {
  @Ref('snapshotsChart') private readonly snapshotsChart: HTMLDivElement
  @Ref('discardChart') private readonly discardChart: HTMLDivElement
  @Ref('radarChart') private readonly radarChart: HTMLDivElement
  @Ref('qualityPieChart') private readonly qualityPieChart: HTMLDivElement
  @Ref('qualityBarChart') private readonly qualityBarChart: HTMLDivElement

  private pickerOptions: DatePickerOptions = {
    onPick: ({ maxDate, minDate }) => {
      if (minDate && !maxDate) {
        this.minDate = minDate
      } else {
        this.minDate = null
      }
    },
    disabledDate: date => {
      const isAfterToday = this.$moment(date).isAfter(this.$moment())

      if (this.minDate) {
        const isOutRange =
          this.$moment(date).isBefore(this.$moment(this.minDate).subtract(30, 'days')) ||
          this.$moment(date).isAfter(this.$moment(this.minDate).add(30, 'days'))

        return isOutRange || isAfterToday
      }

      return isAfterToday
    }
  }
  private areaList: AnyObj[] = []
  private dateRange: [Date, Date] = [
    this.$moment().startOf('day').toDate(),
    this.$moment().endOf('day').toDate()
  ]
  private selectedAreas: AnyObj[] = []
  private currentAreaId = ''
  private previewUrl = ''
  private previewLoading = false
  private store = store
  private snapshotsTabValue: SnapshotsTabValue = 'total'
  private discardTabValue: DiscardTabValue = 'unqualifiedRate'
  private loading = false
  selectedAreaTag: any = []
  // quality接口返回的有数据的点
  renderAreas: any = []
  currentAreaName = ''
  // 当前区域下的设备列表
  currentAreaDevices: any = []
  currentDevice: any = ''

  get areaName() {
    return this.currentAreaName == '' ? '-' : this.currentAreaName
  }

  private get filterData() {
    const [startDate, endDate] = this.dateRange

    return {
      startTime: this.$moment(startDate).startOf('day').toISOString(),
      endTime: this.$moment(endDate).endOf('day').toISOString(),
      areaIds: this.selectedAreas,
      offset: 0,
      limit: store.dateRange.length * this.selectedAreas.length
    }
  }

  private get totals() {
    return this.store.getTotals()
  }

  private get mostReason() {
    const data = this.store.getTotalsWithReasons()

    if (data.length > 0) {
      return data.reduce((a, b) => {
        if (a.name === '质量合格') return b
        if (b.name === '质量合格') return a
        if (a.value === b.value) return { name: '无', value: 0 }

        return a.value > b.value ? a : b
      }).name
    }

    return ''
  }

  @Watch('currentAreaId')
  async watchCurrentLocationId(currentAreaId: any) {
    this.store.setOptions({ currentAreaId })
    this.currentAreaName =
      this.renderAreas.filter((item: any) => item.areaId === currentAreaId)[0]?.areaName ?? ''
    if (currentAreaId) {
      await this.getCurrentAreaDevices(currentAreaId)
    }
    this.renderLocationCharts()
  }

  @Watch('currentDevice')
  watchCurrentDevice() {
    this.fetchPreviewVideo()
  }

  @Watch('renderAreas')
  watchRenderAreas(renderAreas: any) {
    this.store.setOptions({ renderAreas })
  }

  @Watch('snapshotsTabValue')
  watchSnapshotsTabValue() {
    this.renderSnapshotsChart()
  }

  @Watch('discardTabValue')
  watchDiscardTabValue() {
    this.renderDiscradChart()
  }

  created() {
    this.fetchAreaList()
  }

  getStatus(status: number) {
    const map: Record<number, string> = { 0: '在线', 1: '离线', 9: '未知' }

    return map[status] ?? ''
  }

  async getCurrentAreaDevices(currentAreaId: any) {
    const res = await await this.$api.base.getDevicesSearch({
      areaIds: [currentAreaId],
      cascade: true,
      limit: 100,
      offset: 0
    })
    if (res?.data?.data?.length > 0) {
      this.currentAreaDevices = res.data.data
      this.currentDevice = this.currentAreaDevices[0].id
    } else {
      this.currentAreaDevices = []
      this.currentDevice = ''
    }
  }

  getAreaPath(areaId: string) {
    const path: AnyObj[] = []

    if (areaId) {
      let done = false

      const traverse = (nodes: AnyObj[]) => {
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i]
          if (!done) {
            if (i > 0) {
              path.pop()
            }
            path.push(node)
            if (node.id === areaId) {
              done = true
              return
            } else {
              const children = node.children
              if (children) {
                traverse(children)
              }
            }
          }
        }

        if (!done) {
          path.pop()
        }

        return
      }

      traverse(this.areaList)
    }

    return path
  }

  async fetchAreaList() {
    const areas = await stores.dispatch(actionTypes.FETCH_BASE_AREAS)

    if (areas) {
      this.areaList = areas
    }
  }

  async fetchData() {
    if (this.selectedAreas.length > 0) {
      this.loading = true

      const { data } = await this.$api.base.getQualityStatistic(this.filterData)

      this.loading = false

      if (data.code === 0 && data.data.length > 0) {
        this.store.setData(data.data)
        this.renderAreas = _.uniqWith(
          data.data.map((item: any) => ({
            areaId: item.areaId,
            areaName: item.areaName
          })),
          _.isEqual
        )
        this.currentAreaId = this.renderAreas[0].areaId

        this.renderSnapshotsChart()
        this.renderDiscradChart()
        this.renderLocationCharts()
        return
      }
    }
    this.renderAreas = []
    this.currentAreaId = ''
    this.currentAreaName = ''
    this.currentAreaDevices = []
    this.currentDevice = ''
    this.store.setData([])
    this.disposeCharts()
  }

  restore() {
    store.restore()
    this.disposeCharts()

    this.dateRange = [this.$moment().startOf('day').toDate(), this.$moment().endOf('day').toDate()]
    this.selectedAreas = []
    this.renderAreas = []
    this.currentAreaDevices = []
    this.currentDevice = ''
    this.currentAreaId = ''
    this.currentAreaName = ''
  }

  disposeCharts() {
    for (const key in this.$refs) {
      const ref = this.$refs[key]
      if (ref instanceof HTMLDivElement) {
        charts.dispose(ref)
      }
    }
  }

  renderSnapshotsChart() {
    this.$nextTick(() => {
      if (this.snapshotsChart) {
        charts.snapshots(this.snapshotsChart, this.snapshotsTabValue)
      }
    })
  }

  renderDiscradChart() {
    this.$nextTick(() => {
      if (this.discardChart) {
        if (this.discardTabValue === 'unqualifiedRate') {
          charts.discard(this.discardChart)
        } else {
          charts.snapshots(this.discardChart, this.discardTabValue)
        }
      }
    })
  }

  renderLocationCharts() {
    this.$nextTick(() => {
      if (this.radarChart) charts.comparison(this.radarChart)
      if (this.qualityPieChart) charts.qualityTotals(this.qualityPieChart)
      if (this.qualityBarChart) charts.quality(this.qualityBarChart)
    })
  }

  async fetchPreviewVideo() {
    this.previewUrl = ''

    if (this.currentAreaId && this.currentDevice) {
      this.previewLoading = true

      const { data: blob } = await this.$api.base.getDevicePreview(this.currentDevice)

      this.previewLoading = false

      if (blob.type === 'application/json') {
        const data = await blobToJson(blob)

        this.$message({ message: data.error?.description, type: 'error' })
      } else {
        this.previewUrl = URL.createObjectURL(blob)
      }
    }
  }

  formatDate(str: string) {
    return str ? this.$moment(str).format('YYYY-MM-DD HH:mm:ss') : ''
  }

  dateChange() {
    const { startTime, endTime } = this.filterData

    this.store.setOptions({ startTime, endTime })
    this.fetchData()
  }

  areasChange() {
    this.fetchData()
  }

  clearArea() {
    this.renderAreas = []
    this.currentAreaId = ''
    this.currentAreaName = ''
    this.currentAreaDevices = []
    this.currentDevice = ''
    this.store.setData([])
    this.disposeCharts()
  }
}
