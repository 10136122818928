import { variables } from '@/libs/theme'
import _ from 'lodash'
import store from './store'

const chartColors = [
  '#5470c6',
  '#91cc75',
  '#fac858',
  '#ee6666',
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#9a60b4',
  '#ea7ccc',
  '#8896b3'
]

const colors = {
  dottedLine: '#46565C',
  radarLine: '#204F42'
}

const axisOption = (option: AnyObj) =>
  _.merge(
    {
      color: chartColors,
      textStyle: { color: variables.textColorSecondary },
      legend: {
        icon: 'rect',
        top: 10,
        right: 10,
        itemWidth: 8,
        itemHeight: 8,
        itemGap: 32,
        textStyle: { color: variables.textColorRegular, fontSize: 14 },
        type: 'scroll',
        pageIconSize: 12,
        pageIconColor: variables.textColorRegular,
        pageIconInactiveColor: variables.textColorDatePickerDisabled,
        pageTextStyle: {
          color: variables.textColorRegular
        },
        padding: [0, 0, 0, 50]
      },
      tooltip: {
        trigger: 'axis',
        borderWidth: 0,
        backgroundColor: variables.backgroundColorPopper,
        textStyle: { fontSize: 14, color: variables.textColorRegular },
        axisPointer: {
          type: 'cross'
        }
      },
      xAxis: {
        type: 'category',
        nameTextStyle: { color: variables.textColorSecondary },
        axisLine: { show: false },
        data: store.dateRange.map(date => date.format('MM-DD'))
      },
      yAxis: {
        splitLine: { lineStyle: { type: 'dotted', color: colors.dottedLine } },
        axisLine: { show: false },
        type: 'value'
      }
    },
    option
  )

const charts = {
  snapshots: (element: HTMLDivElement, prop = 'total') => {
    const data = store.getSnapshotsData()
    if (data.length > 0) {
      const chart = echarts.init(element)

      chart.clear()
      chart.setOption(
        axisOption({
          series: data.map(({ name, data }) => ({
            name,
            data: data.map((item: AnyObj) => item[prop]),
            type: 'line'
          }))
        })
      )
    } else {
      charts.dispose(element)
    }
  },

  discard: (element: HTMLDivElement) => {
    const data = store.getSnapshotsData()

    if (data.length > 0) {
      const chart = echarts.init(element)

      chart.clear()
      chart.setOption(
        axisOption({
          yAxis: {
            axisLabel: { formatter: '{value}%' }
          },
          series: store.getSnapshotsData().map(({ name, data }) => ({
            name,
            data: data.map((item: AnyObj) => item.unqualifiedRate),
            type: 'line'
          }))
        })
      )
    } else {
      charts.dispose(element)
    }
  },

  comparison: (element: HTMLDivElement) => {
    const data = store.getComparisonData()

    const maxValue = data.map(({ current, others }) => {
      if (Math.max(current, others) !== 0) {
        return Math.max(current, others)
      }
      return 1
    })

    if (data.some(({ current, others }) => current + others > 0)) {
      echarts.init(element).setOption({
        color: ['#EFDD79', '#4EC7A4'],
        tooltip: {
          backgroundColor: variables.backgroundColorPopper,
          borderWidth: 0,
          textStyle: { color: variables.textColorRegular },
          formatter: ({ name: seriesName }: AnyObj) => {
            const values = data.map(({ name, current, others }) => ({
              name,
              value: seriesName === '当前区域' ? current : others
            }))

            return `<div>
              <div>
                <span style="line-height: 28px">${seriesName}</span>
              <div>

              ${values
                .map(({ name, value }) => `<div><span>${name}：</span> <span>${value}</span></div>`)
                .join('')}
              </div>
            `
          }
        },
        legend: {
          orient: 'vertical',
          right: 40,
          bottom: 20,
          itemWidth: 8,
          itemHeight: 8,
          textStyle: { color: variables.textColorRegular, fontSize: 14 },
          data: ['当前区域', '其余所选区域']
        },
        radar: {
          radius: '65%',
          name: { textStyle: { color: '#13e6aa', fontSize: 12 } },
          splitArea: { show: true, areaStyle: { color: 'rgba(0, 0, 0, 0)' } },
          splitLine: {
            lineStyle: {
              color: colors.radarLine,
              width: 1
            }
          },
          axisLine: { lineStyle: { color: colors.radarLine, width: 1 } },
          shape: 'circle',
          indicator: data.map(({ name }, i) => ({ name, max: maxValue[i] }))
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                value: data.map(({ current }) => current),
                name: '当前区域',
                areaStyle: { opacity: 0.2 }
              },
              {
                value: data.map(({ others }) => others),
                name: '其余所选区域',
                areaStyle: { opacity: 0.2 }
              }
            ]
          }
        ]
      })
    } else {
      charts.dispose(element)
    }
  },

  qualityTotals: (element: HTMLDivElement) => {
    const data = store.getTotalsWithReasons()

    if (data.some(({ value }) => value > 0)) {
      echarts.init(element).setOption({
        color: chartColors,
        legend: {
          left: 60,
          bottom: 60,
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 32,
          textStyle: { color: variables.textColorRegular, fontSize: 14 },
          data: data.map(({ name }) => name)
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} <br/> 比例 {d}%',
          backgroundColor: variables.backgroundColorPopper,
          borderWidth: 0,
          textStyle: { color: variables.textColorRegular }
        },
        series: [
          {
            type: 'pie',
            radius: '55%',
            center: ['50%', '40%'],
            label: { color: variables.textColorRegular },
            itemStyle: { borderWidth: 0 },
            data: data.filter(({ value }) => value > 0)
          }
        ]
      })
    } else {
      echarts.dispose(element)
    }
  },

  quality: (element: HTMLDivElement) => {
    const data = store.getDataWithReasons()

    if (data.some(({ data }) => data.some(i => i > 0))) {
      const qualifiedSeries = data
        .filter(({ name }) => name === '质量合格')
        .map(({ name, data }) => ({
          name,
          data,
          type: 'bar',
          stack: 'qualified'
        }))

      const unqualifiedSeries = data
        .filter(({ name }) => name !== '质量合格')
        .map(({ name, data }) => ({
          name,
          data,
          type: 'bar',
          stack: 'unqualified'
        }))

      const totalSeries = [...qualifiedSeries, ...unqualifiedSeries].map(item => ({
        ...item,
        stack: 'total',
        tooltip: { show: false }
      }))

      const series = [...qualifiedSeries, ...unqualifiedSeries, ...totalSeries].map(item => ({
        ...item,
        barMaxWidth: 20,
        barCategoryGap: '40%'
      }))

      echarts.init(element).setOption(
        axisOption({
          color: chartColors,
          legend: { data: store.getReasons(), textStyle: { fontSize: 14 } },
          tooltip: {},
          xAxis: [
            {
              type: 'category',
              data: store.dateRange.map(date => date.format('MM-DD'))
            }
          ],
          yAxis: { type: 'value' },
          series
        })
      )
    } else {
      charts.dispose(element)
    }
  },

  dispose: (element: HTMLDivElement) => {
    echarts.init(element).dispose()
  }
}

export default charts
